<template>
    <Teste />
</template>

<script>
import Teste from '@/components/Teste.vue'
export default {
  components: {Teste},
  data(){
    return{}
  }
}
</script>

<style>

</style>